<template>
  <div class="main-contents">
      <div class="Img">
          <img :src="item.articleImg" alt="">
      </div>
      <div class="title">
          <div class="title-title">{{item.title}}</div>
          <div class="title-content">{{item.synopsis}}</div>
          <div class="title-time">{{item.articleCreateDate}}</div>
      </div>
      <div class="cancels" @click.stop="cancelMethod(item.booksId)">取消收藏</div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    methods:{
        /**取消收藏**/
        cancelMethod(ID){
            this.$emit('wzcancelMethod',ID)
        }
    }
}
</script>

<style scoped lang="scss">
.main-contents{
    width: 895px;
    height: 150px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0px;
    margin-bottom: 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    .Img{
        width: 139px;
        height: 105;
        margin-left: 20px;
        background-color: red;
        display: flex;
        justify-items: center;
        align-items: center;
        > img{
            width: 100%;
            height: 100%;
        }
    }
    .title{
        height: 105px;
        width: 490px;
        margin-left: 20px;
        padding-right: 102px;
        .title-title{
            font-size: 16px;
            line-height: 22px;
            text-align: justify;
            color: #333333;
            height: 30px;
        }
        .title-content{
            font-size: 14px;
            line-height: 20px;
            text-align: justify;    
            color: #666666;
            height: 50px;
            overflow: hidden;
        }
        .title-time{
            font-size: 12px;
            line-height: 17px;
            text-align: justify;
            color: #666666;
            margin-top: 10px;
            height: 15px;
        }
    }
    .cancels{
        width: 104px;
        height: 42px;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 16px;
        line-height: 42px;
        color: #666666;
        text-align: center;
        cursor: pointer;
    }
}
</style>
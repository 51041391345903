<template>
  <div class="main-content">
    <!-- 头 -->
    <div class="top" :style="{backgroundColor:isSkin}">
       <div class="thumb-title">我的收藏</div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="content-top">
        <div class="content-one" :style="number==1?{backgroundColor:isSkin,color:'#FFF'}:''" @click="cilckMethodOne">图书</div>
        <div class="content-two" :style="number==2?{backgroundColor:isSkin,color:'#FFF'}:''" @click="cilckMethodTwo">文章</div>
      </div>
      <!-- 图书 -->
      <div class="content-list" v-if="number==1">
        <div class="tu-list">
          <bookList v-for="(item,index) in bookList" :key="index" :index="index" :item="item" @cancelMethod="cancelMethod"></bookList>
        </div>
        <div class="page" v-show="bpageShow">
            <div class="page1">
                <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
          </div>
      </div>
      <!-- 文章 -->
      <div class="article-list" v-if="number==2">
        <articleList v-for="(item,index) in articleList" :key="index" :item="item" @wzcancelMethod="wzcancelMethod"></articleList>
        <div class="page" v-show="apageShow">
            <div class="page1">
                <el-pagination background :current-page="currentPage"
                  :page-size="page1Size" layout="prev, pager, next" :total="totals1" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import bookList from './box/bookList-box'
import articleList from './box/articleList-box'
export default {
  components:{bookList,articleList},
  data() {
    return {
      apageShow:false,
      bpageShow:false,
      totals:1,
      totals1:1,
      currentPage:1,
      current1Page:1,
      pageSize:10,
      page1Size:10,
      number:1,
      articleList:[],
      bookList:[],
    }
  },
  computed:{
    isSkin(){
		return this.$store.state.isSkin
    },
  },
  created(){
    this.handleCurrentChange(1)
    this.getMemberCollection()
  },
  methods:{
    /**获取收藏**/
    getMemberCollection(){
      var that = this
      that.$api.getMemberCollection({
        params:{
          type:that.number,
          pageSize:that.number==1?that.pageSize:that.page1Size,
          pageNum:that.number==1?that.currentPage:that.current1Page,
        }
      }).then(res=>{
        if (res.data.code == 0) {
          if (that.number == 2) {
            that.articleList = res.data.data.list
            that.totals = res.data.data.pages
            if (res.data.data.list.length == 0){
              that.apageShow = false
            }else {
              that.apageShow = true
            }
          }else{
            that.bookList = res.data.data.list
            that.totals1 = res.data.data.pages
            if (res.data.data.list.length == 0){
              that.bpageShow = false
            }else {
              that.bpageShow = true
            }
          }
        }else{
          if (that.number == 2) {
            that.articleList = []
            that.apageShow = false
          }else{
            that.bookList = []
            that.bpageShow = false
          }
        }
      })
    }, 
    /**图书取消收藏**/ 
    cancelMethod(bookID,category){
      var that = this
      that.$api.getBooksBrowse({
        booksId:bookID,
        category:category
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '取消收藏成功！',
            type: 'success'
          })
          this.getMemberCollection()
        }else{
          Message({
            showClose: true,
            message: '取消收藏失败！',
            type: 'error'
          })
        }
      })
    },
    /**文章取消收藏**/ 
    wzcancelMethod(ID){
      var that = this
			that.$api.getNewCollection({
				articleId:ID
			}).then(res=>{
				if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '取消收藏成功！',
            type: 'success'
          })
          this.getMemberCollection()
        }else{
          Message({
            showClose: true,
            message: '取消收藏失败！',
            type: 'error'
          })
        }
      })
    },
    /**图书按钮**/ 
    cilckMethodOne(){
      this.number = 1
      this.handleCurrentChange(1)
      this.getMemberCollection()
    },
    /**文章按钮**/
    cilckMethodTwo(){
      this.number = 2
      this.getMemberCollection()
      this.handleCurrentChange(1)
    },
    /**当前页按钮**/ 
    handleCurrentChange(index) {
      if (this.number == 1) {
        this.currentPage = index
      }else{
        this.current1Page = index
      }
      this.getMemberCollection()
      this.$nextTick(function () {
        /**更换分页按钮皮肤**/
        var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
        if(array && array.length>0){
          array.forEach((item,index1) => {
            if (index == array[index1].innerText) {
              array[index1].style.background  = this.isSkin;
            }else{
              array[index1].style.background  = "#f4f4f5";
            }
          })
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  .top{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    line-height: 55px;
    .thumb-title{
      font-size: 18px;
      color: #FFFFFF;
      margin-left: 12px;
    }
  }
  .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-top{
      height: 50px;
      width: 388px;
      display: flex;
      justify-items: center;
      align-items: center;
      padding: 30px 0px 30px 0px;
      .content-one{
        width: 144px;
        height: 46px;
        line-height: 46px;
        border: 1px solid #DDD;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        color: #333;
        cursor: pointer;
      }
      .content-two{
        margin-left: 100px;
        width: 144px;
        height: 46px;
        line-height: 46px;
        border: 1px solid #DDD;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        color: #333;
        cursor: pointer;
      }
    }
    .content-list{
      width: 100%;
      .tu-list{
        width: 100%;
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .page{
          width: 896px;
          padding: 20px 20px 20px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .page1{
            display: flex;
            justify-items: center;
            align-items: center;
          }
        }
    }
    .article-list{
      width: 100%;
      .page{
          width: 896px;
          padding: 20px 20px 20px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .page1{
            display: flex;
            justify-items: center;
            align-items: center;
          }
        }
    }
  }
}
</style>
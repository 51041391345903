<template>
  <div class="main-contents" :class="{distance0:number==0,distance1:(index+1)%2==0}">
      <div class="Img">
          <img :src="item.booksImg" alt="">
      </div>
      <div class="title">
          <div class="title-title">{{item.booksName}}</div>
          <div class="title-content">{{item.press}}</div>
      </div>
      <div class="cancels" @click.stop="cancelMethod(item.booksId,item.category)">取消收藏</div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            number:0
        }
    },
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        index:{
            type:Number,
            default:0
        },
    },
    methods:{
        /**取消收藏**/
        cancelMethod(bookID,category){
            this.$emit('cancelMethod',bookID,category)
        },
    }
}
</script>

<style scoped lang="scss">
.main-contents{
    width: 438px;
    height: 100px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    margin-bottom: 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    .Img{
        width: 60px;
        height: 60px;
        background-color: yellowgreen;
        margin-left: 20px;
        display: flex;
        justify-items: center;
        align-items: center;
        > img{
            width: 100%;
            height: 100%;
        }
    }
    .title{
        height: 60px;
        width: 200px;
        padding-left: 10px;
        padding-right: 20px;
        .title-title{
            font-size: 16px;
            line-height: 35px;
            height: 35px;
            color: #333333;
        }
        .title-content{
            font-size: 12px;
            line-height: 20px;
            color: #999999;
            height: 20px;
        }
    }
    .cancels{
        width: 104px;
        height: 42px;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 16px;
        line-height: 42px;
        color: #666666;
        text-align: center;
        cursor: pointer;
    }
}
.distance0{
    margin-right: 19px;
}
.distance1{
    margin-right: 0px;
}
</style>